<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-image', params: { accountId: this.$route.params.accountId } }">Images</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ image.label }}</h1>
                <!-- <p class="text-caption text-center">Edit image</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10" v-if="image">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0 mt-8">Label</p>
                    <p class="mb-0 pb-0">
                        {{ image.label }}
                    </p>

                    <!-- show preview only if image has a file_id -->
                    <v-row no-gutters align="center" style="width: 300px;" v-if="image.file_id">
                        <!-- TODO: just one file ??? that's a problem, how do we do variants?  different sizes? this needs to be a table or expansion list with variants -->
                        <ImageBox :imageId="image.id" :etag="image.etag" :width="200" contain/>
                        <!-- <v-btn class="blue--text no-print" @click="dialogEditRealmLogoImage = true" icon>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn> -->
                    </v-row>

                    <!-- <p class="text-overline mb-0 mt-8">Comment</p>
                    <p class="mb-0 pb-0">
                        {{ image.comment }}
                        <v-btn icon color="green" @click="editImageComment">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p> -->

                    <p class="text-overline mb-0 mt-8">Image ID</p>
                    <p class="mb-0 pb-0">
                        {{ image.id }}
                    </p>

                    <!-- <p class="text-overline mb-0 mt-8">Security</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, imageId: this.$route.params.imageId } }">Access control</router-link>
                    </p> -->
                    <!-- <template v-if="isPermitServiceAdmin">
                    <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                    <p class="mb-0 pb-0">
                    </p> -->
                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Image Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.form, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import ImageBox from '@/components/ImageBox.vue';
// import ImageList from '@/components/account-dashboard/ImageList.vue';

export default {
    components: {
        // ImageList,
        ImageBox,
    },
    data: () => ({
        error: null,
        image: null,
    }),
    computed: {
        isViewReady() {
            return this.image !== null;
        },
    },
    methods: {
        async loadImage() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadImage: true });
                const response = await this.$client.maing().image.get({ id: this.$route.params.imageId });
                console.log(`loadImage: response ${JSON.stringify(response)}`);
                if (response) {
                    this.image = response;
                    // TODO: set the editable fields instead, like this.editableDisplayname = response.comment ?? '';
                    // this.image.comment ??= '';
                    // this.image.reply_to ??= '';
                    this.$set(this.image, 'etag', Date.now()); // force ImageBox to reload
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load image');
                }
            } catch (err) {
                console.error('failed to load image', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadImage: false });
            }
        },
    },
    mounted() {
        this.loadImage();
    },
};
</script>
